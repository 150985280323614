<template>
  <ion-segment
    scrollable
    mode="md"
    color="primary"
    class="tabs compact no-shadow bg-transparent w-100"
    :value="activeIndex"
    @ion-change="segmentChanged($event)"
  >
    <ion-segment-button v-for="(tab, index) of tabs" :key="index" class="bg-transparent w-100 tab" :value="index">
      <ion-label>{{ tab.name }}<span v-if="tab.name === 'Requests' && unreadRequestCount" class="ml-1 unread"><ion-badge><span>{{ unreadRequestCount }}</span></ion-badge></span></ion-label>
    </ion-segment-button>
  </ion-segment>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { Tab } from '@/shared/types/static-types';

@Options({
  name: 'NotificationsTabs',
  components: {},
})
export default class NotificationsTabs extends Vue {
  @Prop({ type: Array }) public tabs!: Tab[];
  @Prop({ type: Number, default: 0 }) public activeIndex!: number;
  @Prop({ type: Number, default: 0 }) public unreadRequestCount!: number;

  public segmentChanged($event: CustomEvent) {
    const tabIndex = $event.detail.value as number;
    const tab = this.tabs[tabIndex];
    if (+tabIndex === 1) {
      this.$emit('resetUnreadRequestCount');
    }
    this.$emit('update:activeIndex', tabIndex);
    this.$emit('tab-changed', tab);
  }
}
</script>

<style scoped lang="sass">
.tab
  max-width: unset !important
.shrink
  flex: none !important
.unread
  top: 2px
  position: relative
  ion-badge
    width: 20px
    height: 20px
    border-radius: 50%
    span
      top: 1px
      position: relative
      color: white
      text-align: center
      margin: 0 auto
</style>

<template>
  <ion-page class="page scrollable">
    <!-- ion-content -->
    <div class="bg-transparent">
      <div class="title p-3 d-flex justify-content-between align-items-center">
        <div class="title-text">Notifications</div>
        <ion-custom-button v-if="!loading" :loading="markLoading" class="mark-btn" @click.stop="doMarkAllAsRead">
          Mark all as read
        </ion-custom-button>
      </div>
      <NotificationsTabs
        v-model="activeIndex"
        :tabs="tabs"
        :unread-request-count="unreadRelationshipNotifCount"
        @tabChanged="fetch"
        @resetUnreadRequestCount="doResetUnreadRequestCount"
      />
      <infinite-notifications :loading="loading" class="my-2" @refetch="fetch" />
    </div>
  </ion-page>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Action } from 's-vuex-class';
import InfiniteNotifications from './components/InfiniteNotifications.vue';
import NotificationsTabs from './components/NotificationsTabs.vue';
import namespace from '@/shared/store/namespace';
import { fetchNotifications } from '@/shared/actions/notifications';
import { authStore } from '@/shared/pinia-store/auth';

@Options({
  name: 'NotificationsPage',
  components: {
    InfiniteNotifications,
    NotificationsTabs,
  },
})
export default class NotificationsPage extends Vue {
  public loading = false;
  public markLoading = false;

  public get isAuthenticated() {
    const { isAuthenticated } = authStore();
    return isAuthenticated.value;
  }

  @Action('getNotifications', { namespace: namespace.NotificationsModule })
  public getNotifications!: any;

  @Action('markAllAsRead', { namespace: namespace.NotificationsModule })
  public markAllAsRead!: any;

  public async doMarkAllAsRead() {
    try {
      this.markLoading = true;
      await this.markAllAsRead();
    } catch (error) {
    } finally {
      this.loading = false;
      this.markLoading = false;
    }
  }

  public activeIndex = 0;
  public unreadRelationshipNotifCount = 0;

  public tabs = [
    { name: 'All', value: 0, key: 'all' },
    {
      name: 'Requests',
      key: 'relationships',
      value: 1,
    },
  ];

  public async fetch() {
    this.loading = true;
    const tab = this.tabs.find((tab) => tab.value === Number(this.activeIndex));

    if (!tab) return;

    const { key } = tab;

    await this.getNotifications({ page: 1, pageSize: 20, markAsRead: true, type: key === 'all' ? 'exclude_now' : key });
    this.loading = false;
  }

  public doResetUnreadRequestCount() {
    this.unreadRelationshipNotifCount = 0;
  }

  public async mounted() {
    this.loading = true;
    const unreadData = await fetchNotifications(1, 20, false, 'relationships');
    this.unreadRelationshipNotifCount = unreadData.count;

    await this.getNotifications({ page: 1, pageSize: 20, markAsRead: true, type: 'exclude_now' });
    this.loading = false;
  }
}
</script>
<style lang="sass" scoped>
.mark-btn
  max-width: 200px
  margin-top: -10px
  ::v-deep
    .custom-button
      display: flex
      align-items: center
      justify-content: center
    ion-button
      height: 36px !important
      --background: #7050B7 !important
    *
      text-transform: unset !important
      margin: 0 !important
@media(max-width: 570px)
  .title-text
    font-size: 25px !important
  .mark-btn
    max-width: 150px
.title
  font-size: 36px
  font-weight: bold
  color: #214163
</style>
